import { BxsChevronRight } from '@/assets/icons/funds/BxsChevronRight';
import BaseTopNav from '@/components/base/baseTopNav';
import TradingHistoryDetailButton from '@/pages/funds/tradingHistoryDetail/components/TradingHistoryDetailButton';
import RenderUtil from '@/utils/RenderUtil';
import { toJsonData } from '@/utils/socket';
import useUrlState from '@ahooksjs/use-url-state';
import { useTranslation } from 'react-i18next';
import {useModel} from "@@/exports";

export default () => {
  const { t } = useTranslation(); // 使用翻译钩子获取翻译函数
  const [urlState] = useUrlState<any>({});
  const { data } = urlState || {};
  const renderData = toJsonData(data);
  const {gotoCustomerService} = useModel("imSocket")

  return (
    <div>
      <BaseTopNav></BaseTopNav>
      <div className=" p-4">
        {/* 详情标题 */}
        <div className=" mb-4">
          <div className=" flex flex-col items-center gap-2">
            {/* <div className="font-bold text-2xl">
        {RenderUtil.FormatAmount(data?.amount)}
      </div> */}
            <div className="flex gap-2 font-bold text-2xl">{t('系统通知')}</div>
          </div>
        </div>
        {/* 详情信息 */}
        <div>
          <div>
            <div className="p-4 border-1 border-backgroundAuxiliaryColor rounded-md flex flex-col gap-4 text-sm">
              <div className="justify-between items-center">
                <div className=" text-auxiliaryTextColor flex items-center pt-2">
                  {t('消息内容')}
                </div>
                <div className=" font-bold">{renderData?.noJsonMsg}</div>
              </div>

              <div className="  justify-between items-center">
                <div className=" text-auxiliaryTextColor">{t('创建时间')}</div>
                <div className=" text-auxiliaryTextColor font-bold">
                  {RenderUtil.formatDate(renderData?.createTime,"YYYY-MM-DD HH:mm:ss")}
                </div>
              </div>
            </div>
            <div
              className=" flex items-center justify-between mt-4 font-bold"
              onClick={() => {
                gotoCustomerService();

              }}
            >
              <div>{t('对消息有疑问')}</div>
              <BxsChevronRight></BxsChevronRight>
            </div>
          </div>
        </div>
      </div>
      {/* 底部按钮 */}
      <TradingHistoryDetailButton
        data={undefined}
        type={undefined}
      ></TradingHistoryDetailButton>
    </div>
  );
};
