import { Button } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import {
  CompletedOrderOrderProcessEnum,
  CompletedOrderOrderStatusEnum,
  CustomerServiceTypeEnum,
  RechargeChannelTypeEnum,
} from '@/enums/businessEnum';
import { useLocation } from '@@/exports';
import { useReq } from '@/services/net/request';
import { cancelWithdrawApi, rechargeDetail, withdrawDetail } from '@/services/api/requestApi';
import { toast } from '@/layouts/components/ToastMessage';

export default ({ data, type }: any) => {
  const { t } = useTranslation();
  const { isMobile } = useModel('system');
  const { gotoC2CCustomerService, gotoCustomerService } = useModel('imSocket');
  const { refreshWallet } = useModel('user');
  const { state }: any = useLocation();
  const { id } = state;
  const { run } = useReq(cancelWithdrawApi,{
    manual:true,
    onSuccess(){
      toast.success(t("已取消"))
      refreshWallet()
      history.back()
    }
  });


  return (
    <div
      className={` ${
        isMobile ? 'fixed' : 'relative'
      }   bottom-4 w-full pb-2 pt-2 bg-background justify-between px-4 ${
        !isMobile ? 'pt-10' : ''
      }`}
    >
      {
        data?.status?.toString() === CompletedOrderOrderStatusEnum.UnderReview && <Button
          onClick={() => {
            run({id: id})
          }}
          className="px-10 mainColorButton !bg-backgroundAuxiliaryColor w-full   !text-foreground "
        >
          {t('取消提现')}
        </Button>
      }
      <Button
        onClick={() => {
          if (type === +CompletedOrderOrderProcessEnum.ADD && data?.channelId) {
            gotoC2CCustomerService({
              groupType: data?.rechargeType,
              channelId: data?.channelId,
              rechargeType: data?.rechargeType,
            });
            return;
          }
          gotoCustomerService();
        }}
        className="px-10 mainColorButton w-full  bg-primary mt-4 text-backgroundTextColor"
      >
        {t('咨询客服')}
      </Button>

    </div>
  );
};
